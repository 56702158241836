export default [
  {
    path: 'by_week',
    name: 'agenda_list',
    component: () => import('./List'),
  },
  {
    path: 'by_date',
    name: 'agenda_by_date',
    component: () => import('./AgendaByDate.vue'),
  },
]
