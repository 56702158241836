var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-header",
    {
      staticClass:
        "border-bottom header bg-primary-dark text-white items-center flex fixed inset-x-0 top-0 z-50",
    },
    [
      _c(
        "button",
        {
          class: [
            "text-white hover:bg-primary-light w-10 h-10 flex items-center justify-center rounded-full bg-primary nav-toggle",
            { "nav-toggle-on": _vm.navCollapse },
          ],
          on: { click: _vm.toggleNavCollapse },
        },
        [
          _c(
            "svg",
            {
              staticClass: "fill-current w-6 h-6",
              attrs: {
                focusable: "false",
                viewBox: "0 0 24 24",
                "aria-hidden": "true",
              },
            },
            [
              _c("g", [
                _c("path", {
                  attrs: { d: "M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" },
                }),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "ml-32" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("h1", { staticClass: "text-base ml-8 text-white" }, [
              _vm._v("麦芽儿门店管理"),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "flex-grow" }),
      _c(
        "el-dropdown",
        { staticClass: "flex mr-8 relative" },
        [
          _c("img", {
            staticClass: "h-12 object-contain logo",
            attrs: { src: require("@/assets/maiya.jpg") },
          }),
          _vm.unread_message_count
            ? _c("el-badge", {
                staticClass: "absolute top-0 -right-3",
                attrs: { value: _vm.unread_message_count },
              })
            : _vm._e(),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                [
                  _c("router-link", { attrs: { to: { name: "message" } } }, [
                    _c(
                      "div",
                      { staticClass: "flex px-4 text-base items-center w-40" },
                      [
                        _c("span", [_vm._v(" 查看消息 ")]),
                        _c("div", { staticClass: "flex-grow" }),
                        _vm.unread_message_count
                          ? _c("el-badge", {
                              staticClass: "mt-3",
                              attrs: { value: _vm.unread_message_count },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.user
        ? _c(
            "el-dropdown",
            {
              attrs: { trigger: "click" },
              on: { command: _vm.handleMenuCommand },
            },
            [
              _c("span", { staticClass: "el-dropdown-link text-white" }, [
                _vm._v(" " + _vm._s(_vm.user.name)),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                    _vm._v("登出"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }