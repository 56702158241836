<template>
  <div>
    <header class="mb-8">
      {{ title }}
    </header>
    <el-card>
      <el-form ref="form" :model="form" inline @submit.prevent.native="search">
        <el-form-item label="会员手机号" prop="mobile">
          <el-input v-model="form.mobile" />
        </el-form-item>
        <el-form-item label="付费时间" prop="date">
          <el-date-picker
            v-model="form.date"
            :editable="false"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item v-if="staffs" label="员工" prop="staff_id">
          <el-select v-model="form.staff_id" filterable>
            <el-option
              v-for="staff of staffs"
              :key="staff.id"
              :label="staff.name"
              :value="staff.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="业务场景" prop="scene">
          <el-select v-model="form.scene">
            <el-option value="">全部</el-option>
            <el-option
              v-for="(key, value) of Scene"
              :key="'source' + value"
              :label="key"
              :value="value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="exportData">导出</el-button>
        </el-form-item>
      </el-form>
      <span>共{{ total }}条记录，共计 {{ totalAmount || 0 }}元</span>
      <el-table :data="income">
        <el-table-column label="订单时间" prop="paid_at" />
        <!--        <el-table-column label="业务场景">-->
        <!--          <template v-slot="{ row }">-->
        <!--            <div>-->
        <!--              {{ Scene[row.scene] }}-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label="业务场景">
          <template #default="{ row }">
            <div>
              {{ Scene[row.scene] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员手机号码">
          <template #default="{ row: { user } }">
            <div>
              {{ user.mobile }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="员工姓名">
          <template #default="{ row: { staff } }">
            <div>
              {{ staff?.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-slot="{ row }" label="相关单据号">
          <span>{{ row.voucher_id }}</span>
          <span v-if="row.refunded" class="text-red-500">(已退款)</span>
        </el-table-column>
        <el-table-column label="微信/支付宝商户订单号" prop="out_trade_no" />
        <el-table-column label="支付金额" prop="amount" />
        <el-table-column label="备注" prop="remark" />
        <el-table-column label="详情">
          <template #default="{ row: { order_id } }">
            <div>
              <router-link
                v-if="order_id"
                :to="{
                  name: 'order_detail',
                  params: {
                    id: order_id,
                  },
                }"
              >
                <el-button type="text">详情</el-button>
              </router-link>
              <span v-else>无</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center">
        <Pagination
          :current-page="page"
          :page-size="per_page"
          :total="total"
          @size-change="onPageSizeChange"
          @current-change="changePage"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
import dayjs from 'dayjs'
const Source = {
  M_POS: 'POS消费',
  MINIAPP: '小程序',
}
const Scene = {
  M_POS: 'POS订单',
  RECHARGE: '会员充值',
  MEMBER_BUYING: '会员卡购买',
  // COUPON_PACKAGE: '票券套餐',
  // CONSULT: '网上问诊',
  APPOINTMENT: '预约',
  GOODS_BUYING: '网店购买',
}
import api from '@/api'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    payChannel: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        mobile: '',
        date: [],
        source: '',
        scene: '',
        staff_id: '',
      },
      staffs: [],
      Scene,
      Source,
      income: [],
      total: 0,
      page: 1,
      per_page: 20,
      totalAmount: 0,
    }
  },
  watch: {
    '$route.query'() {
      this.init()
    },
  },
  mounted() {
    const query = this.$route.query
    if (!query.paid_at__gt) {
      query.paid_at__gt = dayjs().startOf('day').format('YYYY-MM-DD')
    }
    if (!query.paid_at__lt) {
      query.paid_at__lt = dayjs().startOf('day').format('YYYY-MM-DD')
    }

    this.init(false)
    this.search()
  },
  methods: {
    init(fetchData = true) {
      const query = this.$route.query
      if (Object.keys(query).length) {
        for (let field of Object.keys(this.form)) {
          this.form[field] = query[field]
        }
        if (query.staff_id) {
          this.form.staff_id = +query.staff_id
        }
        this.form.date = query.paid_at__gt
          ? [query.paid_at__gt, query.paid_at__lt]
          : []
      } else {
        this.$refs.form.resetFields()
      }
      if (fetchData) {
        this.fetchData()
      }
    },
    fetchData() {
      const query = this.$route.query
      const params = {
        ...query,
        page: query.page || 1,
        per_page: query.per_page || 20,
      }
      api.staff.list({ per_page: 999 }).then(({ data }) => {
        this.staffs = data.items
      })
      api.income
        .list({
          ...params,
          pay_channel: this.payChannel,
        })
        .then(({ data }) => {
          this.income = data.items
          this.totalAmount = data.total_amount
          this.total = data.total
        })
    },
    search() {
      const { date, ...common } = this.form
      const [paid_at__gt, paid_at__lt] = date || []
      this.updateQuery({ ...common, paid_at__gt, paid_at__lt, page: 1 })
    },
    onPageSizeChange(per_page) {
      this.updateQuery({ per_page, page: 1 })
    },
    changePage(page) {
      this.updateQuery({ page })
    },
    exportData() {
      const { date, ...common } = this.form
      const [paid_at__gt, paid_at__lt] = date
      const params =
        date.length > 0
          ? {
              ...common,
              paid_at__gt,
              paid_at__lt,
            }
          : {
              ...common,
            }
      api
        .exportIncomeItem({
          ...params,
          pay_channel: this.payChannel,
        })
        .catch(error => {
          this.$message({ message: error, type: 'error' })
        })
    },
  },
}
</script>

<style scoped></style>
